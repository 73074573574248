import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSelectors } from "@/store/slices/auth";
import { getAccessibleRoutes } from "@/utils/routeUtils";
import { allPrivateRoutes } from "..";

const RouteGuard = ({
  children,
  mustAuthenticated = false,
  mustNotAuthenticated = false,
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const navigate = useNavigate();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const userRoles = useSelector((state) => state.auth.userRoles);

  // For redirect path
  // const prevLocation = useLocation();
  // const searchParams = new URLSearchParams(window.location.search);
  // const queryString = searchParams.get("redirectTo");
  // const tabType = searchParams.get("type");

  useEffect(() => {
    setShowChildren(false);

    if (mustAuthenticated && !isLoggedIn) {
      navigate("/sign-in");

      // For redirect path
      // const redirectPath = tabType
      //   ? `/sign-in?redirectTo=${encodeURIComponent(prevLocation.pathname)}?type=${tabType}`
      //   : `/sign-in?redirectTo=${encodeURIComponent(prevLocation.pathname)}`;
      // navigate(redirectPath);
    } else if (mustNotAuthenticated && isLoggedIn) {
      if (userRoles?.length) {
        const allAccessibleRoutes = getAccessibleRoutes(
          allPrivateRoutes,
          userRoles
        );
        const firstAccessibleRoute = `/${allAccessibleRoutes[0].path}`;

        navigate(firstAccessibleRoute);
      }

      // For redirect path
      // const redirectPath = queryString ? decodeURIComponent(queryString) : "/";
      // navigate(redirectPath);
    } else {
      setShowChildren(true);
    }
  }, [
    isLoggedIn,
    mustAuthenticated,
    mustNotAuthenticated,
    userRoles,
    allPrivateRoutes,

    // For redirect path
    // prevLocation.pathname,
    // queryString,
    // tabType,
  ]);

  return <>{!!showChildren && children}</>;
};

export default RouteGuard;
