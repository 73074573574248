import { Navigate } from "react-router-dom";
import { protectedRoute } from "@/utils/routeUtils";

const moduleTitle = "module_title.settings";
const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const dropdownListRoutes = {
  path: "dropdown-list",
  title: moduleTitle,
  sidebarProps: {
    key: "2-1",
    iconSrc: "dropdown-list",
    label: "module_title.dropdown_list",
    link: "/dropdown-list/human-resource-management",
    groupingType: "SETTINGS",
    order: 1,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: (
        <Navigate to="/dropdown-list/human-resource-management" replace />
      ),
    },
    {
      path: "human-resource-management",
      title: moduleTitle,
      element: protectedRoute(
        [
          () => import("@/pages/dropdown-list/DropdownList"),
          () =>
            import(
              "@/pages/dropdown-list/human-resource-management/HumanResourceMgmtSettings"
            ),
        ],
        allowedRoles
      ),
    },
    {
      path: "company-management",
      title: moduleTitle,
      element: protectedRoute(
        [
          () => import("@/pages/dropdown-list/DropdownList"),
          () =>
            import(
              "@/pages/dropdown-list/company-management/CompanyMgmtSettings"
            ),
        ],
        allowedRoles
      ),
    },
    {
      path: "rental-management",
      title: moduleTitle,
      element: protectedRoute(
        [
          () => import("@/pages/dropdown-list/DropdownList"),
          () =>
            import(
              "@/pages/dropdown-list/rental-management/RentalMgmtSettings"
            ),
        ],
        allowedRoles
      ),
    },
  ],
};

export default dropdownListRoutes;
