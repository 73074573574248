import { i18next } from "@/locale";

// Basic Rules
export const requiredRules = () => {
  return [{ required: true, message: i18next.t("rules_message.required") }];
};

export const spaceRules = () => {
  return [
    {
      pattern: "^(?! )[\\s\\S]*(?<! )$",
      message: i18next.t("rules_message.space_rules"),
    },
  ];
};

// String-Based Rules
export const fileTitleRules = () => {
  return [
    {
      pattern: "^[a-zA-Z0-9 ]+$",
      message: i18next.t("rules_message.file_title_rules"),
    },
  ];
};

export const alphanumericRules = () => {
  return [
    {
      pattern: "^^[a-zA-Z0-9]*$",
      message: i18next.t("rules_message.alphanumeric_rules"),
    },
  ];
};

export const emailRules = () => {
  return [{ type: "email", message: i18next.t("rules_message.email_rules") }];
};

export const passwordRules = () => {
  return [
    { required: true, message: i18next.t("rules_message.password_rules") },
  ];
};

export const confirmPasswordRules = () => {
  return [
    {
      required: true,
      message: i18next.t("rules_message.confirm_password_rules"),
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(i18next.t("rules_message.not_matching_passwords"))
        );
      },
    }),
  ];
};

export const urlRules = () => {
  return [{ type: "url", message: i18next.t("rules_message.url_rules") }];
};

export const maxCharactersRules = (maxCharacters) => {
  return [
    {
      max: maxCharacters,
      message: `${i18next.t("rules_message.max_characters_rules")} ${maxCharacters} ${i18next.t("rules_message.characters")}`,
    },
  ];
};

// Number-Based Rules
export const numberRules = () => {
  return [
    { pattern: "^^[0-9]*$", message: i18next.t("rules_message.number_rules") },
  ];
};

export const numberAndHyphensRules = () => {
  return [
    {
      pattern: "^^[0-9-]*$",
      message: i18next.t("rules_message.number_and_hyphens_rules"),
    },
  ];
};

export const moneyRules = () => {
  return [
    // eslint-disable-next-line
    {
      pattern: "^[0-9]+(?:.[0-9]{1,2})?$",
      message: i18next.t("rules_message.money_rules"),
    },
  ];
};

export const numberRangeRules = (minValue, maxValue) => {
  return [
    {
      type: "number",
      min: minValue,
      max: maxValue,
      message: `${i18next.t("rules_message.number_range_rules")} ${minValue} ${i18next.t("rules_message.and")} ${maxValue}`,
    },
  ];
};
