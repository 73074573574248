import { useApi } from "@/utils/request";

export const useNotificationApi = () => {
  const [api, apiWithoutAxiosError] = useApi();

  const getNotificationList = (data) =>
    api({
      url: "/notification/list",
      method: "post",
      data,
    });

  const readNotification = (data) =>
    api({
      url: "/notification/update-has-read",
      method: "post",
      data,
    });

  const readAllNotification = (data) =>
    api({
      url: "/notification/update-all-has-read",
      method: "post",
      data,
    });

  const checkNotification = () =>
    apiWithoutAxiosError({
      url: "/notification/check",
      method: "get",
    });

  return {
    getNotificationList,
    readNotification,
    readAllNotification,
    checkNotification,
  };
};
