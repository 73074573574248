import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

const initialState = {
  locale: "en",
  currentRouteObj: {},
  selectedCompanyDetails: {},
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLocale(state, action) {
      state.locale = action.payload;
    },
    setCurrentRouteObj(state, action) {
      state.currentRouteObj = action.payload;
    },
    setSelectedCompanyDetails(state, action) {
      state.selectedCompanyDetails = action.payload;
    },
  },
});

const allActions = appSlice.actions;

export const appActions = {
  // all reducer
  ...allActions,
  // all actions (might override reducer if needed)
  setLocale: (value) => {
    return async (dispatch) => {
      dispatch(allActions.setLocale(value));
      i18next.changeLanguage(value);
    };
  },
};

export default appSlice.reducer;
