import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const utilitiesRoutes = {
  path: "utilities",
  title: "module_title.utilities_management",
  sidebarProps: {
    key: "1-12",
    iconSrc: "utilities",
    label: "module_title.utilities",
    link: "/utilities",
    groupingType: "TOOLS",
    order: 12,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/utilities/UtilitiesList")],
    allowedRoles
  ),
};

export default utilitiesRoutes;
