import { ConfigProvider, App } from "antd";
import { NOTIFY_POSITION, NOTIFY_DURATION } from "@/constants";

const antDesignTheme = {
  // GLOBAL token
  token: {
    colorPrimary: "#a9dfd8",
    fontFamily: "inherit",
    colorTextBase: "#fbfbfb",
    colorError: "#FF6F70",
  },
  // COMPONENTS token
  components: {},
};

const AntDesignConfig = (props) => {
  const { children } = props || {};

  return (
    <ConfigProvider theme={antDesignTheme}>
      <App>{children}</App>
    </ConfigProvider>
  );
};

export const notificationConfig = {
  placement: NOTIFY_POSITION,
  duration: NOTIFY_DURATION,
};

export default AntDesignConfig;
