import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const telcoServicesRoutes = {
  path: "telco-services",
  title: "module_title.telco_services_management",
  sidebarProps: {
    key: "1-10",
    iconSrc: "telco-services",
    label: "module_title.telco_services",
    link: "/telco-services",
    groupingType: "TOOLS",
    order: 10,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/telco-services/TelcoServicesList")],
    allowedRoles
  ),
};

export default telcoServicesRoutes;
