import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const officeRentalRoutes = {
  path: "permit",
  title: "module_title.permit_management",
  sidebarProps: {
    key: "1-9",
    iconSrc: "permit",
    label: "module_title.permit",
    link: "/permit",
    groupingType: "TOOLS",
    order: 9,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/permit/PermitList")],
    allowedRoles
  ),
};

export default officeRentalRoutes;
