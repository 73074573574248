import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const accommodationRentalRoutes = {
  path: "accommodation-rental",
  title: "module_title.accommodation_rental_management",
  sidebarProps: {
    key: "1-8",
    iconSrc: "accommodation-rental",
    label: "module_title.accommodation_rental",
    link: "/accommodation-rental",
    groupingType: "TOOLS",
    order: 8,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/accommodation-rental/AccommRentalList")],
    allowedRoles
  ),
};

export default accommodationRentalRoutes;
