import { protectedRoute } from "@/utils/routeUtils";
import RedirectToEmployeeBasicInfo from "../components/RedirectToEmployeeBasicInfo";

const moduleTitle = "module_title.employee_management";
const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const employeeRoutes = {
  path: "employee",
  title: moduleTitle,
  sidebarProps: {
    key: "1-6",
    iconSrc: "employee",
    label: "module_title.employee",
    link: "/employee",
    groupingType: "TOOLS",
    order: 6,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: protectedRoute(
        [() => import("@/pages/employee/EmployeeList")],
        allowedRoles
      ),
    },
    {
      path: ":id",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          headerProps: { backUrl: "/employee" },
          element: <RedirectToEmployeeBasicInfo />,
        },
        {
          path: "employee-basic-info",
          title: moduleTitle,
          headerProps: { backUrl: "/employee" },
          element: protectedRoute(
            [
              () => import("@/pages/employee/EmployeeDetails"),
              () =>
                import(
                  "@/pages/employee/employee-basic-info/EmployeeBasicInfo"
                ),
            ],
            allowedRoles
          ),
        },
        {
          path: "passport-visa-info",
          title: moduleTitle,
          headerProps: { backUrl: "/employee" },
          element: protectedRoute(
            [
              () => import("@/pages/employee/EmployeeDetails"),
              () =>
                import("@/pages/employee/passport-visa-info/PassportVisaInfo"),
            ],
            allowedRoles
          ),
        },
        {
          path: "spouse-visa-info",
          title: moduleTitle,
          headerProps: { backUrl: "/employee" },
          element: protectedRoute(
            [
              () => import("@/pages/employee/EmployeeDetails"),
              () => import("@/pages/employee/spouse-visa-info/SpouseVisaInfo"),
            ],
            allowedRoles
          ),
        },
        {
          path: "dependant-visa-info",
          title: moduleTitle,
          headerProps: { backUrl: "/employee" },
          element: protectedRoute(
            [
              () => import("@/pages/employee/EmployeeDetails"),
              () =>
                import(
                  "@/pages/employee/dependant-visa-info/DependantVisaInfo"
                ),
            ],
            allowedRoles
          ),
        },
        {
          path: "employment-info",
          title: moduleTitle,
          headerProps: { backUrl: "/employee" },
          element: protectedRoute(
            [
              () => import("@/pages/employee/EmployeeDetails"),
              () => import("@/pages/employee/employment-info/EmploymentInfo"),
            ],
            allowedRoles
          ),
        },
      ],
    },
  ],
};

export default employeeRoutes;
