import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const officeRentalRoutes = {
  path: "office-rental",
  title: "module_title.office_rental_management",
  sidebarProps: {
    key: "1-7",
    iconSrc: "office-rental",
    label: "module_title.office_rental",
    link: "/office-rental",
    groupingType: "TOOLS",
    order: 7,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/office-rental/OfficeRentalList")],
    allowedRoles
  ),
};

export default officeRentalRoutes;
