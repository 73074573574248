import styled from "styled-components";
import SvgIcon from "@/components/SvgIcon";
import { Button, Dropdown, Flex } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useNotificationApi } from "@/api/notification";
import { CHECK_NOTIFICATION_INTERVAL_IS_MS } from "@/constants";
import { authActions } from "@/store/slices/auth";

const RawBoHeader = ({ className, setNotifDrawerOpen, isSideBarExpanded }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { type } = useParams();
  const [searchParams] = useSearchParams();
  const listType = searchParams.get("type");
  const { checkNotification } = useNotificationApi();

  const [hasNotification, setHasNotification] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const currentRouteObj = useSelector((state) => state.app.currentRouteObj);
  const userProfile = useSelector((state) => state.auth.profileObj);

  const isMatchingBasePath = ["/work-order", "/ciis"].some((basePath) =>
    new RegExp(`^${basePath}(/\\d+)?$`).test(pathname)
  );

  useEffect(() => {
    if (isMatchingBasePath) {
      handleCheckNotification();

      const notificationLoop = setInterval(
        handleCheckNotification,
        CHECK_NOTIFICATION_INTERVAL_IS_MS
      );

      return () => {
        clearInterval(notificationLoop);
      };
    }
  }, [pathname]);

  const handleCheckNotification = async () => {
    try {
      const res = await checkNotification();
      if (res.status !== "ok") return;

      setHasNotification(res.result);
    } catch (error) {
      console.error("Error checking notification", error);
    }
  };

  const profileItems = [
    {
      key: "company-profile",
      label: (
        <p onClick={() => navigate("/profile")}>{t("general.edit_profile")}</p>
      ),
    },
    {
      key: "sign-out",
      label: (
        <p onClick={() => dispatch(authActions.logout())}>
          {t("general.sign_out")}
        </p>
      ),
    },
  ];

  const renderSubRoleLabel = () => {
    const { isReviewer, isRequester, isApprover } = userProfile.userRole || {};
    const labels = [];

    if (isReviewer) {
      labels.push(t("general.reviewer"));
    }
    if (isRequester) {
      labels.push(t("general.requester"));
    }
    if (isApprover) {
      labels.push(t("general.approver"));
    }

    return labels.join(", ");
  };

  return (
    <Flex
      className={`${className} ${isSideBarExpanded ? "header-w-sidebar-expanded" : ""}`}
      justify="space-between"
      align="center"
    >
      <Flex align="center" gap={8}>
        {!!currentRouteObj?.headerProps?.backUrl && (
          <SvgIcon
            src="backward"
            className="pointer"
            onClick={() =>
              navigate(
                type
                  ? `${currentRouteObj?.headerProps?.backUrl?.replace(":type", type)}`
                  : `${currentRouteObj?.headerProps?.backUrl}${listType ? "?type=" + listType : ""}`
              )
            }
          />
        )}
        <h1 className={"page-title " + pathname.split("/")[1]}>
          {t(currentRouteObj.title)}
        </h1>
        <div id="header-tag-container" />
      </Flex>

      <Flex justify="space-between" align="center" gap={24}>
        {isMatchingBasePath && (
          <Button
            className="custom-button no-outline notification-icon"
            onClick={() => setNotifDrawerOpen((prevState) => !prevState)}
          >
            <SvgIcon
              src={hasNotification ? "notification-bing" : "notification"}
            />
          </Button>
        )}
        <Dropdown
          trigger={["click"]}
          placement="bottomLeft"
          overlayClassName="header-profile-dropdown"
          menu={{ items: profileItems }}
          onOpenChange={(open) => setIsDropdownOpen(open)}
        >
          <Flex
            className="profile-container"
            justify="space-between"
            align="center"
          >
            <div className="profile-container-desc">
              <h3 className="profile-container-name">{userProfile.name}</h3>
              <p className="profile-container-role">
                {userProfile.userRole?.role?.name}
              </p>
              <p className="profile-container-sub-role">
                {renderSubRoleLabel()}
              </p>
            </div>
            <SvgIcon
              src={
                isDropdownOpen
                  ? "dropdown-circle-open"
                  : "dropdown-circle-close"
              }
            />
          </Flex>
        </Dropdown>
      </Flex>
    </Flex>
  );
};

const BoHeader = styled(RawBoHeader)`
  height: var(--bo-header-height);
  padding-inline: var(--bo-spacing-inline) 16px;
  position: fixed;
  background-color: #01010b !important;
  z-index: 5 !important;
  width: calc(100% - 4.5rem);
  transition: width 0.6s ease-in-out;

  &.header-w-sidebar-expanded {
    width: calc(100% - 18.19rem) !important;
  }

  .page-title {
    margin-bottom: 0;
    font: normal normal 600 var(--font-size-l) var(--default-font);
    color: #fbfbfb;

    &.dashboard,
    &.dropdown-list,
    &.workflow-setup {
      color: #a1a9b8 !important;
    }
  }

  .header-counting-tag {
    white-space: pre;

    &.user {
      background-color: var(--user-color);
    }
    &.company {
      background-color: var(--company-color);
    }
    &.human-resource {
      background-color: var(--human-resource-color);
    }
    &.office-rental {
      background-color: var(--office-rental-color);
    }
    &.accommodation-rental {
      background-color: var(--accommodation-rental-color);
    }
    &.permit {
      background-color: var(--permit-color);
    }
    &.telco-services {
      background-color: var(--telco-services-color);
    }
    &.facilities {
      background-color: var(--facilities-color);
    }
    &.utilities {
      background-color: var(--utilities-color);
    }
  }

  .notification-icon {
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.75rem 0.688rem;
    border-radius: 0.5rem;
  }

  .profile-container {
    padding: 4px 16px;
    border-radius: 8px;
    cursor: pointer;

    :hover {
      background-color: var(--default-border-color);
    }

    &-desc {
      margin-right: 1rem;
    }

    &-name {
      font: normal normal 600 var(--font-size-s) var(--default-font);
      color: #808ea1;
      margin-bottom: 0.3rem;
    }

    &-role {
      font: normal normal 500 13px var(--default-font);
      color: #e5ecf6;
      margin-bottom: 0.3rem;
    }

    &-sub-role {
      font: normal normal 500 var(--font-size-xs) var(--default-font);
      color: #b3bed0;
    }
  }
`;

export const useHeaderTagContainerEl = () => {
  const [headerTagContainerEl, setHeaderTagContainerEl] = useState(null);

  useEffect(() => {
    setHeaderTagContainerEl(document.getElementById("header-tag-container"));
  }, []);

  return {
    headerTagContainerEl,
  };
};

export default BoHeader;
