import { createBrowserRouter, RouterProvider } from "react-router-dom";
import values from "lodash/values";
import PrivateLayout from "@/layouts/PrivateLayout";
import PublicLayout from "@/layouts/PublicLayout";
import ErrorPage from "@/pages/error-page.js";
import SignIn from "@/pages/sign-in";
import RouteGuard from "./components/RouteGuard";

// Import all modules dynamically
const importAllModules = (context) => {
  const modules = {};
  context.keys().forEach((modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
    modules[moduleName] = context(modulePath).default;
  });
  return modules;
};

// Load modules from the 'modules' directory
const modulesContext = require.context("./modules", true, /\.js$/);
const modules = importAllModules(modulesContext);

const publicRoutes = {
  element: <PublicLayout />,
  children: [
    {
      path: "*",
      element: <ErrorPage type="404" />,
    },
    {
      path: "/sign-in",
      element: (
        <RouteGuard mustNotAuthenticated>
          <SignIn />
        </RouteGuard>
      ),
    },
  ],
};

const privateRoutes = {
  element: <PrivateLayout />,
  children: values(modules),
};

const router = createBrowserRouter([publicRoutes, privateRoutes]);

const Router = () => <RouterProvider router={router} />;

export default Router;
export const allPrivateRoutes = privateRoutes?.children;
