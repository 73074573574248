import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const workflowSetupRoutes = {
  path: "workflow-setup",
  title: "module_title.workflow_setup",
  sidebarProps: {
    key: "2-2",
    iconSrc: "workflow-setup",
    label: "module_title.workflow_setup",
    link: "/workflow-setup",
    groupingType: "SETTINGS",
    order: 2,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/workflow-setup/WorkflowSetupList")],
    allowedRoles
  ),
};

export default workflowSetupRoutes;
