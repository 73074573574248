import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const userRoutes = {
  path: "user",
  title: "module_title.user_management",
  sidebarProps: {
    key: "1-4",
    iconSrc: "user",
    label: "module_title.user",
    link: "/user",
    groupingType: "TOOLS",
    order: 4,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/user/UserList")],
    allowedRoles
  ),
};

export default userRoutes;
