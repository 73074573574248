import { protectedRoute } from "@/utils/routeUtils";

const moduleTitle = "module_title.work_order";
const allowedRoles = ["isRequester", "isReviewer"];

const workOrderRoutes = {
  path: "work-order",
  title: moduleTitle,
  sidebarProps: {
    key: "1-2",
    iconSrc: "work-order",
    label: moduleTitle,
    link: "/work-order",
    groupingType: "TOOLS",
    order: 2,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: protectedRoute(
        [() => import("@/pages/work-order/WorkOrderList")],
        allowedRoles
      ),
    },
    {
      path: ":id",
      title: moduleTitle,
      headerProps: {
        backUrl: "/work-order",
      },
      element: protectedRoute(
        [() => import("@/pages/work-order/WorkOrderDetails")],
        allowedRoles
      ),
    },
  ],
};

export default workOrderRoutes;
