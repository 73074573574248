import { useParams, Navigate } from "react-router-dom";

const RedirectToAppEmployeeBasicInfo = () => {
  const { id, type } = useParams();

  return (
    <Navigate
      to={`/employee-approval/${type}/${id}/employee-basic-info`}
      replace
    />
  );
};

export default RedirectToAppEmployeeBasicInfo;
