import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import SvgIcon from "@/components/SvgIcon";
import { Button, Flex, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNotificationApi } from "@/api/notification";
import { formatTimeAgo } from "@/utils/timeUtils";
import { LoadingOutlined } from "@ant-design/icons";

const defaultFilter = {
  page: 1,
  limit: 10,
  orderCol: "created_date",
  desc: true,
  user: {
    id: null,
  },
};

const RawBoNotification = ({
  className,
  drawerOpen = false,
  setDrawerOpen,
}) => {
  const { t } = useTranslation();
  const {
    checkNotification,
    getNotificationList,
    readNotification,
    readAllNotification,
  } = useNotificationApi();
  const profileObj = useSelector((state) => state.auth.profileObj);

  const [list, setList] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [filter, setFilter] = useState(cloneDeep(defaultFilter));
  const [currentPage, setCurrentPage] = useState(0);
  const [finalPage, setFinalPage] = useState(0);
  const [hasNotification, setHasNotification] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      handleCheckNotification();
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (drawerOpen) {
      getList();
    } else {
      if (!isEqual(filter, defaultFilter)) {
        setFilter(cloneDeep(defaultFilter));
      }
      setList([]);
    }
  }, [drawerOpen, filter]);

  const getList = async () => {
    try {
      setIsLoadingList(true);

      const payload = {
        ...filter,
        user: {
          id: profileObj.id,
        },
      };

      const res = await getNotificationList(payload);
      if (res.status !== "ok") return;

      const notifByCategories =
        Array.isArray(res.result?.list) && !isEmpty(res.result?.list)
          ? categorizeByDate(res.result.list)
          : [];

      const mergeNotifications = (existing = [], incoming = []) => {
        const existingIds = new Set(existing.map((item) => item.id));

        return [
          ...existing,
          ...incoming.filter((item) => !existingIds.has(item.id)),
        ];
      };

      const updatedList = {
        today:
          notifByCategories.today.length > 0
            ? mergeNotifications(list?.today || [], notifByCategories.today)
            : list.today || [],
        last7days:
          notifByCategories.last7days.length > 0
            ? mergeNotifications(
                list?.last7days || [],
                notifByCategories.last7days
              )
            : list.last7days || [],
        older:
          notifByCategories.older.length > 0
            ? mergeNotifications(list?.older || [], notifByCategories.older)
            : list.older || [],
      };

      setList(updatedList);
      setCurrentPage(res.result.page);
      setFinalPage(res.result.finalPage);
    } catch (error) {
      console.error("Error getting notification", error);
    } finally {
      setIsLoadingList(false);
    }
  };

  const hasReadNotification = async (id, hasRead) => {
    if (hasRead) return;

    try {
      const payload = {
        notificationIdList: [id],
      };

      const res = await readNotification(payload);
      if (res.status !== "ok") return;

      refetchList();
    } catch (error) {
      console.error("Error reading notification", error);
    }
  };

  const hasReadAllNotification = async () => {
    if (!hasNotification) return;

    try {
      const payload = {
        user: {
          id: profileObj.id,
        },
      };

      const res = await readAllNotification(payload);
      if (res.status !== "ok") return;

      refetchList();
    } catch (error) {
      console.error("Error reading notification", error);
    }
  };

  const handleCheckNotification = async () => {
    try {
      const res = await checkNotification();
      if (res.status !== "ok") return;

      setHasNotification(res.result);
    } catch (error) {
      console.error("Error checking notification", error);
    }
  };

  const categorizeByDate = (list) => {
    if (isEmpty(list) || !Array.isArray(list)) return;

    const today = dayjs();
    const categories = {
      today: [],
      last7days: [],
      older: [],
    };

    list.forEach((item) => {
      const createdDate = item.createdDate ? dayjs(item.createdDate) : null;
      const diffInHours = createdDate ? today.diff(createdDate, "hour") : null;
      const diffInDays = createdDate ? today.diff(createdDate, "day") : null;

      if (diffInDays === 0 && diffInHours < 24) {
        categories.today.push(item);
      } else if (diffInDays <= 7) {
        categories.last7days.push(item);
      } else {
        categories.older.push(item);
      }
    });

    return categories;
  };

  const refetchList = () => {
    handleCheckNotification();
    setList([]);
    setFilter(cloneDeep(defaultFilter));
  };

  const getStatus = (status, label) => {
    if (!status || !label) return;

    const formattedStatus = status.toLowerCase();
    const formattedLabel = label.split("_").slice(1).join("_").toLowerCase();

    return (
      <Tag className={`status-tag ${formattedStatus}`}>
        {t(`notification_info.${formattedLabel}`)}
      </Tag>
    );
  };

  const getNotifCategorizedByDate = (list) => {
    return list.map(
      ({ id, hasRead, createdDate, actionStatus, type, workOrder, ciis }) => (
        <Flex
          className={`content-row ${!hasRead ? "unread" : ""}`}
          key={id}
          gap={16}
          align="center"
          onClick={() => hasReadNotification(id, hasRead)}
        >
          <div className="icon">
            <SvgIcon src={workOrder ? "notif-work-order" : "notif-ciis"} />
          </div>
          <Flex className="detail" align="center" justify="space-between">
            <Flex vertical gap={4}>
              <Flex className="line-1" gap={10} align="center">
                <p>
                  {t(`notification_info.${workOrder ? "work_order" : "ciis"}`)}
                </p>
                {getStatus(actionStatus, type)}
              </Flex>
              <Flex className="line-2" gap={10} align="center">
                <p>{`${t("notification_info.ref")} ${workOrder?.id || ciis?.id || "-"}`}</p>
                <p>&#8226;</p>
                <p>{formatTimeAgo(createdDate, t)}</p>
              </Flex>
            </Flex>
            {!hasRead && <span className="unread-dots" />}
          </Flex>
        </Flex>
      )
    );
  };

  return (
    <div className={`${className} ${drawerOpen ? "open" : ""}`}>
      <Flex className="header-container" justify="space-between" align="center">
        <p className="title">{t("notification_info.notifications")}</p>
        <Flex justify="space-between" align="center" gap={8}>
          <Button
            className="custom-button no-outline icon"
            onClick={refetchList}
          >
            <SvgIcon src="refresh" />
          </Button>
          <Button
            className="custom-button secondary-transparent"
            onClick={() => hasReadAllNotification()}
            disabled={isEmpty(list) || !hasNotification}
          >
            {t("notification_info.read_all")}
          </Button>
          <Button className="custom-button no-outline icon">
            <SvgIcon src="cross" onClick={() => setDrawerOpen(false)} />
          </Button>
        </Flex>
      </Flex>

      {!isEmpty(list) ? (
        <Flex gap={24} vertical align="center">
          <Flex gap={14} vertical className="content-container">
            {list.today?.length > 0 && (
              <Flex vertical gap={8}>
                <p className="category-title">{t("notification_info.today")}</p>
                <Flex vertical gap={16}>
                  {getNotifCategorizedByDate(list.today)}
                </Flex>
              </Flex>
            )}

            {list.last7days?.length > 0 && (
              <Flex vertical gap={8}>
                <p className="category-title">
                  {t("notification_info.last_7_days")}
                </p>
                <Flex vertical gap={16}>
                  {getNotifCategorizedByDate(list.last7days)}
                </Flex>
              </Flex>
            )}

            {list.older?.length > 0 && (
              <Flex vertical gap={8}>
                <p className="category-title">{t("notification_info.older")}</p>
                <Flex vertical gap={16}>
                  {getNotifCategorizedByDate(list.older)}
                </Flex>
              </Flex>
            )}
          </Flex>
          {currentPage < finalPage && (
            <Button
              className="custom-button secondary-transparent loadmore"
              onClick={() =>
                setFilter((old) => ({ ...old, page: old.page + 1 }))
              }
              disabled={isLoadingList}
            >
              {t("notification_info.load_more")}
            </Button>
          )}
        </Flex>
      ) : (
        <Spin
          spinning={isLoadingList}
          size="large"
          indicator={<LoadingOutlined />}
        >
          <Flex
            className="no-notification-container"
            vertical
            align="center"
            justify="center"
            gap={15}
          >
            <SvgIcon
              src="notification"
              width={26.67}
              height={30}
              color="#808EA1"
            />
            <p className="no-notification-font">
              {t("notification_info.no_notification_yet")}
            </p>
          </Flex>
        </Spin>
      )}
    </div>
  );
};

const BoNotification = styled(RawBoNotification)`
  z-index: 100;
  position: fixed;
  right: 0;
  width: 0;
  height: 100vh;
  background-color: #10111b;
  border-left: 0.063rem solid var(--default-border-color);
  transition: width 0.3s ease-out;
  overflow-y: auto;

  &.open {
    width: 23.125rem;
    padding: 1.375rem;

    .ant-spin {
      height: 85vh;
      background-color: transparent;
    }

    .ant-spin-blur {
      opacity: 0;
    }

    .header-container {
      margin-bottom: 1.5rem;

      .title {
        font: normal normal 700 var(--font-size-l) var(--default-font);
        color: var(--default-font-color);
      }
    }

    .no-notification-container {
      height: 85vh;

      .no-notification-font {
        font: normal normal 600 1.063rem var(--default-font);
        color: #808ea1;
      }
    }

    .loadmore {
      width: 5.813rem;
    }

    .content-container {
      width: 100%;

      .category-title {
        font: normal normal 600 0.938rem var(--default-font);
        color: #b3bed0;
      }

      .content-row {
        border-radius: 0.75rem;
        padding: 1rem 0.75rem;

        &.unread {
          cursor: pointer;
          background-color: var(--base-color);
          padding: 1rem 2rem 1rem 0.75rem;
        }

        &:hover {
          cursor: pointer;
          background-color: #2b2c3a;
          padding: 1rem 2rem 1rem 0.75rem;
        }

        .icon {
          width: 2rem;
          height: 2rem;
          padding: 0.4rem 0.55rem;
        }

        .unread-dots {
          width: 0.5rem;
          height: 0.5rem;
          background: red;
          border-radius: 50%;
        }

        .detail {
          width: 100%;

          .line-1 {
            p {
              font: normal normal 500 0.938rem var(--default-font);
              color: var(--default-font-color);
            }
          }

          .line-2 {
            p {
              font: normal normal 500 0.813rem var(--default-font);
              color: #a3a7ac;
            }
          }
        }
      }
    }
  }
`;

export default BoNotification;
