import dayjs from "dayjs";

export const formatTimeAgo = (date, t) => {
  if (!date) return "-";

  const now = dayjs();
  const createdDate = date ? dayjs(date) : null;

  const diffInYears = now.diff(createdDate, "year");
  if (diffInYears > 0) {
    return `${diffInYears}${t("time.y_ago")}`;
  }

  const diffInSeconds = now.diff(createdDate, "second");
  if (diffInSeconds < 60) {
    return `${diffInSeconds}${t("time.s_ago")}`;
  }

  const diffInMinutes = now.diff(createdDate, "minute");
  if (diffInMinutes < 60) {
    return `${diffInMinutes}${t("time.m_ago")}`;
  }

  const diffInHours = now.diff(createdDate, "hour");
  if (diffInHours < 24) {
    return `${diffInHours}${t("time.h_ago")}`;
  }

  const diffInDays = now.diff(createdDate, "day");
  if (diffInDays <= 7) {
    return `${diffInDays}${t("time.d_ago")}`;
  }

  const diffInWeeks = now.diff(createdDate, "week");
  if (diffInWeeks < 52) {
    return `${diffInWeeks}${t("time.w_ago")}`;
  }
};
