import { Navigate, useParams } from "react-router-dom";

const RouteTypeValidation = ({ allowedTypes, fallback }) => {
  const { type } = useParams();

  if (!allowedTypes.includes(type)) {
    return <Navigate to="/error-page" replace />;
  }

  return fallback;
};

export default RouteTypeValidation;
