import { createSlice } from "@reduxjs/toolkit";
import ls from "localstorage-slim";

const initialState = {
  token: ls.get("token", { decrypt: true }) || null,
  uuid: ls.get("uuid") || null,
  profileObj: {},
  userRoles: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      const token = action.payload;
      state.token = token;

      if (!token) {
        ls.remove("token");
      } else {
        ls.set("token", token, { decrypt: true });
      }
    },
    setUuid(state, action) {
      const uuid = action.payload;
      state.uuid = uuid;

      ls.set("uuid", uuid);
    },
    setProfileObj(state, action) {
      state.profileObj = action.payload;
    },
    setUserRoles(state, action) {
      state.userRoles = action.payload;
    },
  },
});

const allActions = authSlice.actions;

export const authActions = {
  // all reducer
  ...allActions,
  // all actions (might override reducer if needed)
  logout: () => {
    return async (dispatch) => {
      dispatch(allActions.setToken(null));
      dispatch(allActions.setUuid(null));
      dispatch(allActions.setProfileObj({}));
      dispatch(allActions.setUserRoles([]));
      ls.remove("uuid");
      ls.remove("token");
    };
  },
};

export const authSelectors = {
  selectIsLoggedIn: (state) => !!state.auth.token,
};

export default authSlice.reducer;
