import { protectedRoute } from "@/utils/routeUtils";

const moduleTitle = "module_title.customer_invoice_information_sheet";
const allowedRoles = ["isApprover"];

const ciisRoutes = {
  path: "ciis",
  title: moduleTitle,
  sidebarProps: {
    key: "1-3",
    iconSrc: "ciis",
    label: "module_title.ciis",
    link: "/ciis",
    groupingType: "TOOLS",
    order: 3,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: protectedRoute(
        [() => import("@/pages/ciis/CIISList")],
        allowedRoles
      ),
    },
    {
      path: ":id",
      title: moduleTitle,
      headerProps: {
        backUrl: "/ciis",
      },
      element: protectedRoute(
        [() => import("@/pages/ciis/CIISDetails")],
        allowedRoles
      ),
    },
  ],
};

export default ciisRoutes;
