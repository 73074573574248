import { protectedRoute } from "@/utils/routeUtils";

const moduleTitle = "module_title.dashboard";
const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const dashboardRoutes = {
  path: "dashboard",
  title: moduleTitle,
  sidebarProps: {
    key: "1-1",
    iconSrc: "dashboard",
    label: moduleTitle,
    link: "/dashboard",
    groupingType: "TOOLS",
    order: 1,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/dashboard/Dashboard")],
    allowedRoles
  ),
};

export default dashboardRoutes;
