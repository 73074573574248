import { useCustomMediaQuery } from "@/hooks";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  const { isMobile } = useCustomMediaQuery();

  return (
    <div className={isMobile ? "mobile" : ""}>
      <Outlet />
    </div>
  );
};

export default PublicLayout;
