import { protectedRoute } from "@/utils/routeUtils";

const allowedRoles = ["SUPER_ADMIN", "MANAGER", "OFFICER"];

const parametersSetupRoutes = {
  path: "parameters-setup",
  title: "module_title.parameters_setup",
  sidebarProps: {
    key: "2-3",
    iconSrc: "parameters-setup",
    label: "module_title.parameters_setup",
    link: "/parameters-setup",
    groupingType: "SETTINGS",
    order: 3,
    permissions: allowedRoles,
  },
  element: protectedRoute(
    [() => import("@/pages/parameters-setup/ParametersSetup")],
    allowedRoles
  ),
};

export default parametersSetupRoutes;
