import { useRef, useState, useEffect } from "react";

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useCustomMediaQuery = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleViewportChange = () => {
    if (typeof window !== "undefined") {
      const rootStyles = window.getComputedStyle(document.documentElement);
      const MOBILE_MAX_WIDTH_PX = parseInt(
        rootStyles.getPropertyValue("--mobile-max-width"),
        10
      );
      const viewportWidth = window.innerWidth;

      setIsMobile(viewportWidth <= MOBILE_MAX_WIDTH_PX);
    }
  };

  useEffect(() => {
    handleViewportChange();
    window.addEventListener("resize", handleViewportChange);

    return () => {
      window.removeEventListener("resize", handleViewportChange);
    };
  }, []);

  return { isMobile };
};

export const useIsRendered = () => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return { isRendered };
};

export const useClickOutsideAlerter = (refList, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      let haveClickedSelf = false;

      refList.forEach((ref) => {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
        } else {
          haveClickedSelf = true;
        }
      });

      if (!haveClickedSelf) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refList]);
};
